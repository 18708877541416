import { FC } from 'react';
import { Row, Col, Label } from "reactstrap";

import logoGPM from "./assets/img/logos/gpmLogoBlanco.png";
import logoDominos from "./assets/img/logos/Recurso_1.png";
import logoSushito from "./assets/img/logos/sushiittoLogo.png";
import logoArbys from "./assets/img/logos/arbysLogo.png";
import Block from './Components/Block.component';
import colors from './config/Colors.config';

import "./assets/css/logos.css"

const App: FC = () => {
  return (
    <section style={{ width: "100%", height: "100vh" }} >

      <Block height={50} />

      <Row style={{ marginRight: 0, marginLeft: 0 }} >
        <Col style={{ textAlign: "center" }} >
          <Label>
            <img
              className='contenedor-imagen'
              src={logoGPM}
              alt="No se pudo cargar la imagen"
              style={{
                height: 200
              }}
            />
          </Label>
        </Col>
      </Row>

      <Row style={{ marginRight: 0, marginLeft: 0 }} >
        <Col style={{ textAlign: "center" }} >
          <Label style={{ fontWeight: "bold", fontSize: 48, color: colors.coffee }} >
            Corporativo GPM
          </Label>
        </Col>
      </Row>

      <Block height={50} />

      <Row style={{ marginRight: 0, marginLeft: 0 }} >
        <Col style={{ textAlign: "center" }} >
          <Label style={{ fontWeight: "bold", fontSize: 38, color: colors.coffee }} >
            Nuestras Marcas
          </Label>
        </Col>
      </Row>

      <Block height={20} />

      <Row style={{ marginRight: 0, marginLeft: 0 }} >

        <Col style={{ textAlign: "right" }} >
          <img
            className='contenedor-imagen'
            src={logoArbys}
            alt="No se pudo cargar la imagen"
            style={{
              height: 200
            }}
          />
        </Col>
        <Col style={{ textAlign: "center" }} >
          <img
            className='contenedor-imagen'
            src={logoSushito}
            alt="No se pudo cargar la imagen"
            style={{
              height: 140
            }}
          />
        </Col>
        <Col style={{ textAlign: "left" }} >
          <img
            className='contenedor-imagen'
            src={logoDominos}
            alt="No se pudo cargar la imagen"
            style={{
              height: 230
            }}
          />
        </Col>
      </Row>

    </section >
  );
}

export default App;
